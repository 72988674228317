@import url("./assets/fonts/stylesheet.css");

html,
body {
  margin: 0;
  font-family: "RiftSoft", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7fafc;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "RiftSoft", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.page {
  margin-bottom: 100px;
  /* margin-left: 40px; */
  min-height: 100vh;
  width: 80%;
  max-width: 1240px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .page {
    margin: 0 auto;
    width: 90%;
  }
}

.page.reports {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.page.reports .reports {
  /* margin-left: 40px; */
  width: 80%;
  max-width: 1240px;
  margin: 0 auto;
}

.page.login {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.wrapper {
  max-width: 1240px;
  margin: 0 auto;
}

.wrapper h1 {
  font-size: 2.2em !important;
  font-weight: bold !important;
}

.wrapper .tableWrapper > div {
  box-shadow: none;
}

.editOverlay {
  padding: 0 !important;
  background: transparent !important;
}
.editModal {
  position: fixed !important;

  right: 0;
  height: 100%;
  width: 50%;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 2px 9px 0 rgba(120, 47, 10, 0.1) !important;
  border: 0;
}

textarea.questions:focus {
  box-shadow: none !important;
}
.rdt_Table,
.rdt_Pagination,
.rdt_TableHeadRow,
.rdt_TableRow {
  background-color: transparent !important;
}

/* The animation code */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes background {
  0% {
    background-position: 0% 1000000px;
  }

  100% {
    background-position: 0 0;
  }
}
a:focus,
a:active {
  background: none;
}
a:-webkit-any-link:focus {
  outline-offset: none;
}

.dashboard.printing * {
  border-radius: 0 !important;
}

.fadeinDown {
  -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInDown 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeinUp {
  -webkit-animation: fadeInUp 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInUp 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInLeft 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(80px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInRight {
  -webkit-animation: fadeInRight 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInRight 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-80px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
