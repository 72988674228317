.login {
  background-color: #1a202c;
  /* background-color: rgb(26, 32, 44); */
}
.loginLogo {
  background: url("../assets/images/logoNav.svg");
  mix-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: center;
  animation: fadeInLogo 1s;
}
@media only screen and (max-width: 1150px) {
  .loginLogo {
    background-size: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .loginLogo {
    background-size: 400px;
  }
}
@media only screen and (max-width: 608px) {
  .loginLogo {
    background-size: 240px;
  }
}
.login-bg {
  background-size: auto, cover;
  background-repeat: no-repeat, no-repeat;
}
.login-container {
  justify-content: space-evenly;
  width: 80%;
  box-shadow: rgba(17, 71, 42, 0.779) 0px 3.5px 5.5px;
  max-width: 1240px;
  padding: 2em;
  border-radius: 55px;
  background: #ffffff10;
  box-shadow: rgb(0 0 0 / 5%) 0px 3.5px 5.5px;
  backdrop-filter: saturate(100%) blur(5px);
}
@supports not (-webkit-touch-callout: none) {
  .login-container {
    transform: perspective(10vw) rotateX(-1deg) rotateY(2deg);
  }
}
@media only screen and (max-width: 768px) {
  .login-container {
    margin-left: 3vw;
  }
  @supports not (-webkit-touch-callout: none) {
    .login-container {
      transform: perspective(8vw) rotateX(-1deg) rotateY(1deg);
    }
  }
}
@media only screen and (max-width: 400px) {
  .login-container {
    margin-left: 3vw;
  }
  @supports not (-webkit-touch-callout: none) {
    .login-container {
      transform: perspective(15vw) rotateX(-1deg) rotateY(1deg);
    }
  }
}
.loginwrap {
  border-radius: 0 !important;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
.loginBackground {
  position: absolute;
  height: 100vh;
  left: 0;
}

.loginBackground:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  transform-origin: bottom left;
  transform: skew(5deg, 0deg);
}

.loginBackground:before {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #e30014;
  transform-origin: bottom left;
  transform: skew(-2deg, 0deg);
}
@media only screen and (max-width: 768px) {
  .loginBackground:after {
    bottom: 0;
    top: auto;
    height: 55vh;
    transform: none;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  }
  .loginBackground:before {
    bottom: 0;
    top: auto;
    height: 55vh;
    transform: none;
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
  }
  .page {
    margin-bottom: 0;
  }
}

.login-container h1 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.4em;
}
/* @media only screen and (max-width: 1200px) {
  .login-container h1 {
    background-color: lightblue;
  }
} */
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registration-form > input {
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form > input {
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.254) !important;
}
/* Standard syntax */
@keyframes fadeInLogo {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
